
.input-as-status {
    display: flex !important;
    align-items: center !important;
    @include status-circle(transparent);
    .disabledValueContainer {
        @include status-style(0.12rem 0.9rem, 5.9rem, 1.18rem);
        @include status-color(transparent);
        white-space: nowrap;
    }

    &.application-status_ACCOUNT_VERIFICATION,
    &.application-status_ON_KYC,
    &.application-status_VERIFICATION,
    &.application-status_UPDATE_REQUESTED,
    &.application-status_UNDERWRITING,
    &.application-status_SENT {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.application-status_DISBURSED,
    &.application-status_APPROVED {
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }

    &.application-status_ERROR,
    &.application-status_DECLINED,
    &.application-status_CANCELED {
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.application-status_FLORIDA_TAX {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }



    &.loan-status_PENDING {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.loan-status_ACTIVE {
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }

    &.loan-status_REPAID {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.loan-status_FLORIDA_TAX {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.loan-status_OVERDUE {
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.loan-status_WRITTEN_OFF {
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.loan-status_PROCESSING {
        @include status-circle($main-yellow);
        .disabledValueContainer {
            @include status-color($main-yellow);
        }
    }

    &.loan-status_ERROR { 
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.transaction-status_SUCCESSFUL { 
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }


    &.transaction-status_REJECTED { 
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.transaction-status_PENDING { 
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }

    &.transaction-status_CANCELED { 
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }


    &.transaction-status_IN_PROGRESS { 
        @include status-circle($main-gray);
        .disabledValueContainer {
            @include status-color($main-gray);
        }
    }


    &.transaction-status_FAILED { 
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.transaction-status_ERROR {
        @include status-circle($main-red); 
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }


    &.transaction-status_COMPLETED { 
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }
    

    &.transaction-status_ACTIVE {
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }


    &.transaction-status_CANCELED {
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }

    &.collection-status_IN_PROGRESS { 
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }

    &.collection-status_SUCCESSFUL { 
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }

    &.collection-status_FAILED { 
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }


    &.product-status_ACTIVE{
        @include status-circle($main-green);
        .disabledValueContainer {
            @include status-color($main-green);
        }
    }

    &.product-status_INACTIVE{
        @include status-circle($main-red);
        .disabledValueContainer {
            @include status-color($main-red);
        }
    }
}